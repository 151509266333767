<template>
  <header>
    <div class="container">

      <div class="header__row">

        <div class="header__left">

          <div class="header__logo">
            <div class="header__logo--left">
              <a :href="'/'+$i18n.locale +'/account/'">
                <img src="../assets/img/logo.svg" alt="QazInnovations">
              </a>
            </div>
          </div>

        </div>
        <div class="header__right">
          <div class="header__lang">
            <HeaderLang></HeaderLang>
          </div>

          <HeaderBell></HeaderBell>

          <div class="header__login" v-bind:class="{'header__login--active': loginClick}" v-if="$store.state.userObject">
            <div class="header__login--block">

              <div class="header__login--reletive" @click="headerLogin">

                <div class="header__login--left">
                  <div class="header__login--info">
                    <div class="header__login--name">
                      {{ $store.state.userObject.first_name }} {{ $store.state.userObject.last_name }}
                    </div>
                    <div class="header__login--position">
                      {{ $store.state.userObject.role.name }}
                    </div>
                  </div>
                </div>
                <div class="header__login--right">
                  <img src="../assets/img/icon-arrowdown.svg" alt="Выпадающий список">
                </div>
                <div class="header__login--icon">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>

              </div>
              <div class="login__absol">
                <div class="login__absol--list">
                  <ul class="menu">
                    <li>
                      <a @click="logout()">
                        <span class="login__list--left">
                          <img src="../assets/img/icon-logout.svg">
                        </span>
                        <span class="login__list--right">
                          {{ $t('admin.login.logout') }}
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>
          <div class="header__login" v-else>
            <div class="header__login--block">

              <div class="header__login--reletive header__login--circle" @click="headerLogin">

                <div class="header__login--icon">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                </div>

              </div>

            </div>
          </div>

        </div>

      </div>

    </div>
  </header>
</template>

<script>
import HeaderLang from '@/components/HeaderLang';
import HeaderBell from '@/components/HeaderBell';

export default {
  components: {
    HeaderLang,
    HeaderBell
  },
  data: function () {
    return {
      mobileClick: false,
      loginClick: false,
    }
  },
  computed: {
    AppState() {
      return this.$store.state;
    },
  },
  methods: {
    logout() {
      localStorage.removeItem('token');
      delete localStorage.token;
      location.reload();
      this.$router.push({path: '/'+ this.$i18n.locale +'/login'});
    },

    mobileActive() {
      this.mobileClick = true;
      document.body.classList.add('mobile__active');
    },
    headerLogin: function() {
      this.loginClick = !this.loginClick;
    },
  },

}
</script>