<template>
  <div>

    <div class="main__sidebar" v-bind:class="{ 'main__sidebar--active': sidebarClick }">

      <div class="main__sidebar--burger">
        <a class="sidebar__burger" @click="sidebarClick = !sidebarClick">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 12H20" stroke="#292E4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4 6H20" stroke="#292E4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4 18H20" stroke="#292E4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
      </div>

      <nav class="main__sidebar--nav">

        <ul class="menu sidebar__nav sidebar__nav--main" >

          <li class="sidebar-item" :class="{ 'menu-open': menuActive('AccountMain')}">
            <a :href="'/'+ $i18n.locale +'/account/'" class="sidebar-link">
              <i class="sidebar-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.94855 9.1199L11.5885 2.3999L20.2285 9.1199V19.6799C20.2285 20.1891 20.0263 20.6774 19.6662 21.0375C19.3062 21.3976 18.8177 21.5999 18.3085 21.5999H4.86855C4.35933 21.5999 3.87097 21.3976 3.51091 21.0375C3.15083 20.6774 2.94855 20.1891 2.94855 19.6799V9.1199Z" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.70856 21.6V12H14.4685V21.6" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </i>
              {{ $t('admin.sidebar.home') }}
            </a>
          </li>

          <!-- --------------------------- -->
          <li class="sidebar-item" :class="{ 'menu-open': menuActive('PersonalData')}">
            <a :href="'/'+ $i18n.locale +'/account/personal'" class="sidebar-link">
              <i class="sidebar-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19 22H5C3.9 22 3 21.1 3 20V6C3 4.9 3.9 4 5 4H19C20.1 4 21 4.9 21 6V20C21 21.1 20.1 22 19 22Z" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M16 2V4" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8 2V4" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 14C13.6569 14 15 12.6569 15 11C15 9.34315 13.6569 8 12 8C10.3431 8 9 9.34315 9 11C9 12.6569 10.3431 14 12 14Z" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M17 18.5C15.6 17.5 13.9 17 12 17C10.1 17 8.4 17.6 7 18.5" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </i>
              {{ $t('admin.sidebar.personal') }}
            </a>
          </li>
          <li class="sidebar-item sidebar__item--children" v-if="$store.getters.userPermission(['read_application'])" v-bind:class="{'menu-open': menuActive('Applications') || appClick, 'menu-open-arrow': appClick}">
            <a  class="sidebar-link" @click="appClick = !appClick">
              <i class="sidebar-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14 2V8H20" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2 15H12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 18L12 15L9 12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </i>
              {{ $t('admin.sidebar.applications') }}
              <span class="sidebar-arrow">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 9L12 15L18 9" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
            </a>
            <transition name="fade">
              <ul class="menu sidebar__nav sidebar__nav--treeview" v-if="childrenAppActive(['Applications', 'Incubators'])">

                <!-- <li class="sidebar-item" v-if="$store.getters._getUserRoles !== 3 && $store.getters.userPermission(['read_application'])" :class="{ 'menu-open': menuActive('Applications')}"> -->
                <li class="sidebar-item" v-if="$store.getters.userPermission(['read_application'])" :class="{ 'menu-open': menuActive('Applications')}">
                  <a :href="'/'+ $i18n.locale +'/account/applications'" class="sidebar-link">
                    <i class="sidebar-icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14 2V8H20" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2 15H12" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9 18L12 15L9 12" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </i>
                    {{ $t('admin.sidebar.applications_menu.menu1') }}
                  </a>
                </li>
                <li class="sidebar-item" v-if="$store.getters.userPermission(['read_application'])" :class="{ 'menu-open': menuActive('Incubators')}">
                  <a :href="'/'+ $i18n.locale +'/account/incubators'" class="sidebar-link">
                    <i class="sidebar-icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14 2V8H20" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2 15H12" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9 18L12 15L9 12" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </i>
                    {{ $t('admin.sidebar.applications_menu.menu2') }}
                  </a>
                </li>
                <!-- <li class="sidebar-item" v-if="$store.getters.userPermission(['read_application'])" :class="{ 'menu-open': menuActive('Trps')}">
                  <a :href="'/'+ $i18n.locale +'/account/trps'" class="sidebar-link">
                    <i class="sidebar-icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14 2V8H20" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2 15H12" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9 18L12 15L9 12" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </i>
                    {{ $t('admin.sidebar.applications_menu.trp') }}
                  </a>
                </li> -->

              </ul>
            </transition>
          </li>
          <!-- --------------------------- -->

          <li class="sidebar-item sidebar__item--children" v-if="$store.getters.userPermission(['read_applications'])" v-bind:class="{'menu-open': menuActive('ApplicationsAll') || appClick, 'menu-open-arrow': appClick}">
            <a  class="sidebar-link" @click="appClick = !appClick">
              <i class="sidebar-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14 2V8H20" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2 15H12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 18L12 15L9 12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </i>
              {{ $t('admin.sidebar.applications_list') }}
              <span class="sidebar-arrow">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 9L12 15L18 9" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
            </a>
            <transition name="fade">
              <ul class="menu sidebar__nav sidebar__nav--treeview" v-if="childrenAppActive(['ApplicationsAll', 'IncubatorsAll'])">
                <!-- <li class="sidebar-item" v-if="$store.getters._getUserRoles !== 3 && $store.getters.userPermission(['read_applications'])" :class="{ 'menu-open': menuActive('ApplicationsAll')}"> -->
                <li class="sidebar-item" v-if="$store.getters.userPermission(['read_applications'])" :class="{ 'menu-open': menuActive('ApplicationsAll')}">
                  <a :href="'/'+ $i18n.locale +'/account/applications-list'" class="sidebar-link">
                    <i class="sidebar-icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14 2V8H20" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2 15H12" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9 18L12 15L9 12" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </i>
                    {{ $t('admin.sidebar.applications_menu.menu1') }}
                  </a>
                </li>
                <li class="sidebar-item" v-if="$store.getters.userPermission(['read_applications'])" :class="{ 'menu-open': menuActive('IncubatorsAll')}">
                  <a :href="'/'+ $i18n.locale +'/account/incubators-list'" class="sidebar-link">
                    <i class="sidebar-icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14 2V8H20" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2 15H12" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9 18L12 15L9 12" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </i>
                    {{ $t('admin.sidebar.applications_menu.menu2') }}
                  </a>
                </li>
                <!-- <li class="sidebar-item" v-if="$store.getters.userPermission(['read_applications'])" :class="{ 'menu-open': menuActive('TrpsAll')}">
                  <a :href="'/'+ $i18n.locale +'/account/trp-list'" class="sidebar-link">
                    <i class="sidebar-icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14 2V8H20" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2 15H12" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9 18L12 15L9 12" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </i>
                    {{ $t('admin.sidebar.applications_menu.trp') }}
                  </a>
                </li> -->

              </ul>
            </transition>
          </li>

          <li class="sidebar-item" v-if="$store.getters.userPermission(['read_protocols'])" :class="{ 'menu-open': menuActive('ProtocolsAll')}">
            <a :href="'/'+ $i18n.locale +'/account/protocols'" class="sidebar-link">
              <i class="sidebar-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14 2V8H20" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2 15H12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 18L12 15L9 12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </i>
              {{ $t('admin.sidebar.applications_menu.vote') }}
            </a>
          </li>
          <li class="sidebar-item" v-if="$store.getters.userPermission(['read_protocols_group'])" :class="{ 'menu-open': menuActive('ProtocolsGroupAll')}">
            <a :href="'/'+ $i18n.locale +'/account/protocols-group'" class="sidebar-link">
              <i class="sidebar-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14 2V8H20" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2 15H12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 18L12 15L9 12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </i>
              {{ $t('admin.sidebar.applications_menu.protocols') }}
            </a>
          </li>
          <!-- --------------------------- -->

          <li class="sidebar-item" v-if="$store.getters.userPermission(['read_protocols'])" :class="{ 'menu-open': menuActive('ProtocolsAllIncubator')}">
            <a :href="'/'+ $i18n.locale +'/account/protocols-incubator'" class="sidebar-link">
              <i class="sidebar-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14 2V8H20" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2 15H12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 18L12 15L9 12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </i>
              <span>
                {{ $t('admin.sidebar.applications_menu.vote') }}<br>
                <i>({{ $t('admin.sidebar.applications_menu.incubator') }})</i>
              </span>
            </a>
          </li>
          <li class="sidebar-item" v-if="$store.getters.userPermission(['read_protocols_group'])" :class="{ 'menu-open': menuActive('ProtocolsGroupAllIncubator')}">
            <a :href="'/'+ $i18n.locale +'/account/protocols-incubator-group'" class="sidebar-link">
              <i class="sidebar-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14 2V8H20" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2 15H12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 18L12 15L9 12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </i>
              <span>
                {{ $t('admin.sidebar.applications_menu.protocols') }}<br>
                <i>({{ $t('admin.sidebar.applications_menu.incubator') }})</i>
              </span>
            </a>
          </li>
          <!-- --------------------------- -->

          <li class="sidebar-item sidebar__item--children" v-if="$store.getters.userPermission(['read_user'])" v-bind:class="{'menu-open': menuActive('UserManagementMain') || menuClick, 'menu-open-arrow': menuClick}" >
            <a class="sidebar-link" @click="menuClick = !menuClick">
              <i class="sidebar-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091 3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M16 3.13C16.8604 3.35031 17.623 3.85071 18.1676 4.55232C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89318 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </i>
              {{ $t('admin.sidebar.users_all') }}
              <span class="sidebar-arrow">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 9L12 15L18 9" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
            </a>
            <transition name="fade">
              <ul class="menu sidebar__nav sidebar__nav--treeview" v-if="childrenMenuActive(['UserManagementMain'])">

                <li class="sidebar-item" v-if="$store.getters.userPermission(['read_user'])" :class="{ 'menu-open': menuActive('UserManagement')}"><!--  v-if="$store.getters.userPermission(['read_user'])" -->
                  <a :href="'/'+ $i18n.locale +'/account/users/'" class="sidebar-link">
                    <i class="sidebar-icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M14 2V8H20" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2 15H12" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M9 18L12 15L9 12" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </i>
                    {{ $t('admin.sidebar.users') }}
                  </a>
                </li>
                <li class="sidebar-item" v-if="$store.getters.userPermission(['applications_setting'])" :class="{ 'menu-open': menuActive('UserMonitoring')}">
                  <a :href="'/'+ $i18n.locale +'/account/monitoring/'" class="sidebar-link">
                    <i class="sidebar-icon">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 20V10" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 20V4" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6 20V14" stroke="#0A091D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </i>
                    {{ $t('admin.sidebar.monitoring') }}
                  </a>
                </li>
              </ul>
            </transition>
          </li>

          <li class="sidebar-item" v-if="$store.getters.userPermission(['read_role'])" :class="{ 'menu-open': menuActive('RoleManagement')}"><!--v-if="$store.getters.userPermission(['change_role'])" -->
            <a :href="'/'+ $i18n.locale +'/account/roles/'" class="sidebar-link">
              <i class="sidebar-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19 22H5C3.9 22 3 21.1 3 20V6C3 4.9 3.9 4 5 4H19C20.1 4 21 4.9 21 6V20C21 21.1 20.1 22 19 22Z" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M16 2V4" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8 2V4" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 14C13.6569 14 15 12.6569 15 11C15 9.34315 13.6569 8 12 8C10.3431 8 9 9.34315 9 11C9 12.6569 10.3431 14 12 14Z" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M17 18.5C15.6 17.5 13.9 17 12 17C10.1 17 8.4 17.6 7 18.5" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </i>
              {{ $t('admin.sidebar.roles') }}
            </a>
          </li>
          <li class="sidebar-item" v-if="$store.getters.userPermission(['applications_setting'])" :class="{ 'menu-open': menuActive('SettingApplications')}"><!--v-if="$store.getters.userPermission(['change_role'])" -->
            <a :href="'/'+ $i18n.locale +'/account/applications-setting/'" class="sidebar-link">
              <i class="sidebar-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14 2V8H20" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2 15H12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 18L12 15L9 12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </i>
              {{ $t('admin.sidebar.applications_setting') }}
            </a>
          </li>
          <li class="sidebar-item" v-if="$store.getters.userPermission(['applications_setting'])" :class="{ 'menu-open': menuActive('SettingIncubator')}"><!--v-if="$store.getters.userPermission(['change_role'])" -->
            <a :href="'/'+ $i18n.locale +'/account/incubator-setting/'" class="sidebar-link">
              <i class="sidebar-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14 2V8H20" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2 15H12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 18L12 15L9 12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </i>
              {{ $t('admin.sidebar.incubator_setting') }}
            </a>
          </li>
          <li class="sidebar-item" v-if="$store.getters.userPermission(['applications_setting'])" :class="{ 'menu-open': menuActive('SettingTrp')}">
            <a :href="'/'+ $i18n.locale +'/account/trp-setting/'" class="sidebar-link">
              <i class="sidebar-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14 2V8H20" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2 15H12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 18L12 15L9 12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </i>
              {{ $t('admin.sidebar.trp_setting') }}
            </a>
          </li>

          <li class="sidebar-item" v-if="$store.getters.userPermission(['applications_setting'])" :class="{ 'menu-open': menuActive('DirectoryApplications')}"><!--v-if="$store.getters.userPermission(['change_role'])" -->
            <a :href="'/'+ $i18n.locale +'/account/applications-directory/'" class="sidebar-link">
              <i class="sidebar-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14 2V8H20" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2 15H12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 18L12 15L9 12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </i>
              {{ $t('admin.sidebar.applications_directory') }}
            </a>
          </li>
          <li class="sidebar-item" v-if="$store.getters.userPermission(['applications_setting'])" :class="{ 'menu-open': menuActive('BpmnApplications')}"><!--v-if="$store.getters.userPermission(['change_role'])" -->
            <a :href="'/'+ $i18n.locale +'/account/bpmn/'" class="sidebar-link">
              <i class="sidebar-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14 2V8H20" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2 15H12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 18L12 15L9 12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </i>
              {{ $t('admin.sidebar.bpmn') }}
            </a>
          </li>
          <li class="sidebar-item" v-if="$store.getters.userPermission(['applications_setting'])" :class="{ 'menu-open': menuActive('MonitoringDbApplications')}"><!--v-if="$store.getters.userPermission(['change_role'])" -->
            <a :href="'/'+ $i18n.locale +'/account/monitoring-db/'" class="sidebar-link">
              <i class="sidebar-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14 2V8H20" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2 15H12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 18L12 15L9 12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </i>
              {{ $t('admin.sidebar.monitoring_db') }}
            </a>
          </li>
          <li class="sidebar-item" v-if="$store.getters.userPermission(['applications_setting'])" :class="{ 'menu-open': menuActive('NotificationsApplications')}">
            <a :href="'/'+ $i18n.locale +'/account/notifications/'" class="sidebar-link">
              <i class="sidebar-icon">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V7.5L14.5 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V8" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M14 2V8H20" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M2 15H12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 18L12 15L9 12" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </i>
              {{ $t('admin.sidebar.notifications_admin') }}
            </a>
          </li>


        </ul>

      </nav>

    </div>

  </div>
</template>
<script>
export default {
  data: function () {
    return {
      menuClick: null,
      appClick: null,
      logClick: null,
      sidebarClick: false
    }
  },
  props: {
    'active': {
      type: Array,
      default: [],
    }
  },
  computed:{
    childrenAppActive: function () {
      return function (list) {
        if(this.active.length){
          let childrenMenu = this.active.find(item=>{
            return list.find(listItem=>{
              return (item == listItem) ? true : false;
            });
          });
          if(childrenMenu && this.appClick == null){
            this.appClick = true;
          }
        }
        return this.appClick;
      };
    },
    childrenMenuActive: function () {
      return function (list) {
        if(this.active.length){
          let childrenMenu = this.active.find(item=>{
            return list.find(listItem=>{
              return (item == listItem) ? true : false;
            });
          });
          if(childrenMenu && this.menuClick == null){
            this.menuClick = true;
          }
        }
        return this.menuClick;
      };
    },
    childrenLogActive: function () {
      return function (list) {
        if(this.active.length){
          let childrenMenu = this.active.find(item=>{
            return list.find(listItem=>{
              return (item == listItem) ? true : false;
            });
          });
          if(childrenMenu && this.logClick == null){
            this.logClick = true;
          }
        }
        return this.logClick;
      };
    },
  },
  methods: {
    menuActive(menu){
      if(this.active.length){
        return this.active.find(item=>{
          return (item == menu) ? true : false;
        });
      }
      return false;
    },
  },

}
</script>