import i18n from './../i18n';
import { api } from "../boot/axios";
import moment from 'moment';

export const routes = [{
        path: '/',
        redirect: `/${i18n.locale}`
    },
    {
        path: '/:lang',
        component: {
            render(c) { return c('router-view') }
        },
        children: [{
                path: 'account',
                component: () =>
                    import ('../views/administrator/AdminPage.vue'),
                beforeEnter: (to, from, next) => {
                    api.get('profile/me')
                    .then(response => {
                        if (to.meta.accessList && to.meta.accessList.length) {
                            if (response.data && response.data.role.details.length) {
                                let roleAccesses = response.data.role.details.find(element => {
                                    return to.meta.accessList.includes(element.value);
                                });
                                roleAccesses ? next() : window.location = '/' + to.params.lang + '/account';
                            } else {
                                window.location = '/' + to.params.lang + '/account';
                            }
                        } else {
                            next();
                        }
                    })
                    .catch(error => {
                        localStorage.path = to.path;
                        window.location = '/' + to.params.lang + '/login';
                    });
                },
                children: [
                    {
                        path: '/',
                        name: 'AccountMain',
                        meta: { accessList: [] },
                        component: () =>
                            import ('../views/administrator/Main'),
                    },

                    {
                        path: 'users/',
                        component: {
                            render: (h) => h("router-view"),
                        },
                        children: [
                            {
                                path: '/',
                                name: 'UserManagement',
                                meta: { accessList: ['read_user'] },
                                component: () =>
                                    import ('../views/administrator/UserManagement/index'),
                            }, {
                                path: 'create',
                                name: 'UserManagementAdd',
                                meta: { accessList: ['change_user'] },
                                component: () =>
                                    import ('../views/administrator/UserManagement/create'),
                            }, {
                                path: ':id',
                                name: 'UserManagementShow',
                                meta: { accessList: ['read_user'] },
                                component: () =>
                                    import ('../views/administrator/UserManagement/show'),
                            }, {
                                path: ':id/edit',
                                name: 'UserManagementEdit',
                                meta: { accessList: ['change_user'] },
                                component: () =>
                                    import ('../views/administrator/UserManagement/edit'),
                            },
                        ]
                    },
                    {
                        path: 'monitoring/',
                        component: {
                            render: (h) => h("router-view"),
                        },
                        children: [
                            {
                                path: '/',
                                name: 'UserMonitoring',
                                meta: { accessList: ["applications_setting"] },
                                component: () =>
                                    import ('../views/administrator/UserMonitoring/index'),
                            }, {
                                path: ':id',
                                name: 'UserMonitoringViews',
                                meta: { accessList: ["applications_setting"] },
                                component: () =>
                                    import ('../views/administrator/UserMonitoring/show'),
                            },
                        ]
                    },
                    {
                        path: 'monitoring-db/',
                        component: {
                            render: (h) => h("router-view"),
                        },
                        children: [
                            {
                                path: '/',
                                name: 'MonitoringDbApplications',
                                meta: { accessList: ["applications_setting"] },
                                component: () =>
                                    import ('../views/administrator/MonitoringDbApplications/index'),
                            }
                        ]
                    },
                    {
                        path: 'notifications/',
                        component: {
                            render: (h) => h("router-view"),
                        },
                        children: [
                            {
                                path: '/',
                                name: 'NotificationsApplications',
                                meta: { accessList: ["applications_setting"] },
                                component: () =>
                                    import ('../views/administrator/NotificationsApplications/index'),
                            }
                        ]
                    },
                    {
                        path: 'roles/',
                        component: {
                            render: (h) => h("router-view"),
                        },
                        children: [
                            {
                                path: '/',
                                name: 'RoleManagement',
                                meta: { accessList: ['read_role'] },
                                component: () =>
                                    import ('../views/administrator/RoleManagement/index'),
                            }, {
                                path: 'create',
                                name: 'RoleManagementAdd',
                                meta: { accessList: ['change_role'] },
                                component: () =>
                                    import ('../views/administrator/RoleManagement/create'),
                            }, {
                                path: ':id',
                                name: 'RoleManagementShow',
                                meta: { accessList: ['read_role'] },
                                component: () =>
                                    import ('../views/administrator/RoleManagement/show'),
                            }, {
                                path: ':id/edit',
                                name: 'RoleManagementEdit',
                                meta: { accessList: ['change_role'] },
                                component: () =>
                                    import ('../views/administrator/RoleManagement/edit'),
                            },
                        ]
                    },
                    {
                        path: 'applications-setting/',
                        meta: { accessList: ["applications_setting"] },
                        component: {
                            render: (h) => h("router-view"),
                        },
                        beforeEnter: (to, from, next) => {
                            if (to.meta.type && to.meta.page == 'create') {
                                api.get('moduleSettings/index',{
                                    params: {
                                        type: to.meta.type
                                    }
                                })
                                .then(response => {
                                    if (response.data && response.data[0].status == 1) {
                                        let setting = response.data[0];
                                        if (setting.start_date || setting.end_date) {
                                            if (setting.start_date <= moment(new Date()).format('YYYY-MM-DD HH:mm:ss') && setting.end_date >= moment(new Date()).format('YYYY-MM-DD HH:mm:ss')){
                                                next();
                                            } else {
                                                window.location = '/' + to.params.lang + '/account';
                                            }
                                        } else {
                                            next();
                                        }
                                    } else {
                                        window.location = '/' + to.params.lang + '/account';
                                    }
                                })
                                .catch(error => {
                                    if (error.response) {
                                        window.location = '/' + to.params.lang + '/account';
                                    }
                                });
                            } else {
                                next();
                            }
                        },
                        children: [
                            {
                                path: '/',
                                name: 'Applications-settings',
                                meta: { accessList: ['applications_setting'] },
                                component: () =>
                                    import ('../views/administrator/SettingApplications/index'),
                            },
                            {
                                path: 'cett01/',
                                name: 'Applications',
                                meta: { accessList: ['applications_setting'] },
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/SettingApplications/cett01/index'),
                                    },{
                                        path: 'create',
                                        meta: { type: 'cett01', page: 'create' },
                                        component: () =>
                                            import ('../views/administrator/SettingApplications/cett01/create'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/SettingApplications/cett01/show'),
                                    },{
                                        path: ':id/edit',
                                        component: () =>
                                            import ('../views/administrator/SettingApplications/cett01/edit'),
                                    },
                                ]
                            },
                            {
                                path: 'cett02/',
                                name: 'Applications',
                                meta: { accessList: ['applications_setting'] },
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/SettingApplications/cett02/index'),
                                    },{
                                        path: 'create',
                                        meta: { type: 'cett02', page: 'create' },
                                        component: () =>
                                            import ('../views/administrator/SettingApplications/cett02/create'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/SettingApplications/cett02/show'),
                                    },{
                                        path: ':id/edit',
                                        component: () =>
                                            import ('../views/administrator/SettingApplications/cett02/edit'),
                                    },
                                ]
                            },
                            {
                                path: 'trp/',
                                name: 'Applications',
                                meta: { accessList: ['applications_setting'] },
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/SettingApplications/trp/index'),
                                    },{
                                        path: 'create',
                                        meta: { type: 'trp', page: 'create' },
                                        component: () =>
                                            import ('../views/administrator/SettingApplications/trp/create'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/SettingApplications/trp/show'),
                                    },{
                                        path: ':id/edit',
                                        component: () =>
                                            import ('../views/administrator/SettingApplications/trp/edit'),
                                    },
                                ]
                            },
                            {
                                path: 'app/:id',
                                name: 'Applications',
                                meta: { accessList: ['applications_setting'] },
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/SettingApplications/edit'),
                                    },{
                                        path: 'create',
                                        component: () =>
                                            import ('../views/administrator/SettingApplications/constructor/create'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/SettingApplications/constructor/show'),
                                    },{
                                        path: ':id/edit',
                                        component: () =>
                                            import ('../views/administrator/SettingApplications/constructor/edit'),
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        path: 'incubator-setting/',
                        meta: { accessList: ["applications_setting"] },
                        component: {
                            render: (h) => h("router-view"),
                        },
                        children: [
                            {
                                path: '/',
                                name: 'Incubators-setting-list',
                                meta: { accessList: ['applications_setting'] },
                                component: () =>
                                    import ('../views/administrator/SettingIncubator/incubator/list'),
                            },
                            {
                                path: 'app/:id',
                                name: 'Incubators-setting-item',
                                meta: { accessList: ['applications_setting'] },
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/SettingIncubator/edit'),
                                    },{
                                        path: 'create',
                                        component: () =>
                                            import ('../views/administrator/SettingIncubator/incubator/create'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/SettingIncubator/incubator/show'),
                                    },{
                                        path: ':id/edit',
                                        component: () =>
                                            import ('../views/administrator/SettingIncubator/incubator/edit'),
                                    }
                                ]
                            },
                        ],
                    },
                    {
                        path: 'trp-setting/',
                        meta: { accessList: ["applications_setting"] },
                        component: {
                            render: (h) => h("router-view"),
                        },
                        children: [
                            {
                                path: '/',
                                name: 'trp-setting-list',
                                meta: { accessList: ['applications_setting'] },
                                component: () =>
                                    import ('../views/administrator/SettingTrp/incubator/list'),
                            },
                            {
                                path: 'app/:id',
                                name: 'trp-setting-item',
                                meta: { accessList: ['applications_setting'] },
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/SettingTrp/edit'),
                                    },{
                                        path: 'create',
                                        component: () =>
                                            import ('../views/administrator/SettingTrp/incubator/create'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/SettingTrp/incubator/show'),
                                    },{
                                        path: ':id/edit',
                                        component: () =>
                                            import ('../views/administrator/SettingTrp/incubator/edit'),
                                    }
                                ]
                            },
                        ],
                    },
                    {
                        path: 'applications-directory/',
                        meta: { accessList: ["applications_setting"] },
                        component: {
                            render: (h) => h("router-view"),
                        },
                        children: [
                            {
                                path: '/',
                                name: 'DirectoryApplications',
                                component: () =>
                                    import ('../views/administrator/DirectoryApplications/index'),
                            },
                        ],
                    },
                    {
                        path: 'bpmn/',
                        meta: { accessList: ["applications_setting"] },
                        component: {
                            render: (h) => h("router-view"),
                        },
                        children: [
                            {
                                path: '/',
                                name: 'BpmnApplications',
                                component: () =>
                                    import ('../views/administrator/BpmnApplications/index'),
                            },
                        ],
                    },

                    /*---------------------------------*/


                    {
                        path: 'applications-list/',
                        redirect: '/:lang/account',
                        component: {
                            render: (h) => h("router-view"),
                        },
                        children: [
                            {
                                path: '/',
                                name: 'ApplicationsAll',
                                meta: { accessList: ['read_applications'] },
                                component: () =>
                                    import ('../views/administrator/ApplicationsAll/index'),
                            },
                            {
                                path: 'cett01/',
                                name: 'ApplicationsAll',
                                meta: { accessList: ['read_applications'] },
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/ApplicationsAll/cett01/index'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/ApplicationsAll/cett01/show'),
                                    },
                                ]
                            },
                            {
                                path: 'cett02/',
                                name: 'ApplicationsAll',
                                meta: { accessList: ['read_applications'] },
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/ApplicationsAll/cett02/index'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/ApplicationsAll/cett02/show'),
                                    },
                                ]
                            },
                            {
                                path: 'trp/',
                                name: 'ApplicationsAll',
                                meta: { accessList: ['read_applications'] },
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/ApplicationsAll/trp/index'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/ApplicationsAll/trp/show'),
                                    },
                                ]
                            },
                            {
                                path: 'app/:slug',
                                name: 'Applications',
                                meta: { accessList: ['read_application'] },
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/ApplicationsAll/constructor/index'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/ApplicationsAll/constructor/show'),
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        path: 'protocols/',
                        name: 'ProtocolsAll',
                        meta: { accessList: ['read_protocols'] },
                        component: {
                            render: (h) => h("router-view"),
                        },
                        children: [
                            {
                                path: '/',
                                component: () =>
                                    import ('../views/administrator/Protocols/index'),
                            },
                            {
                                path: 'cett01/',
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/Protocols/cett01/index'),
                                    }, {
                                        path: 'create',
                                        component: () =>
                                            import ('../views/administrator/Protocols/cett01/create'),
                                    }, {
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/Protocols/cett01/show'),
                                    }
                                ]
                            },
                            {
                                path: 'cett02/',
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/Protocols/cett02/index'),
                                    }, {
                                        path: 'create',
                                        component: () =>
                                            import ('../views/administrator/Protocols/cett02/create'),
                                    }, {
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/Protocols/cett02/show'),
                                    }
                                ]
                            },
                            {
                                path: 'trp/',
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/Protocols/trp/index'),
                                    }, {
                                        path: 'create',
                                        component: () =>
                                            import ('../views/administrator/Protocols/trp/create'),
                                    }, {
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/Protocols/trp/show'),
                                    }
                                ]
                            },
                            {
                                path: 'app/:slug',
                                name: 'Applications',
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/Protocols/constructor/index'),
                                    },{
                                        path: 'create',
                                        component: () =>
                                            import ('../views/administrator/Protocols/constructor/create'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/Protocols/constructor/show'),
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        path: 'protocols-group/',
                        name: 'ProtocolsGroupAll',
                        meta: { accessList: ['read_protocols_group'] },
                        component: {
                            render: (h) => h("router-view"),
                        },
                        children: [
                            {
                                path: '/',
                                component: () =>
                                    import ('../views/administrator/ProtocolsGroup/index'),
                            },
                            {
                                path: 'cett01/',
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/ProtocolsGroup/cett01/index'),
                                    }, {
                                        path: 'create',
                                        component: () =>
                                            import ('../views/administrator/ProtocolsGroup/cett01/create'),
                                    }, {
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/ProtocolsGroup/cett01/show'),
                                    }
                                ]
                            },
                            {
                                path: 'cett02/',
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/ProtocolsGroup/cett02/index'),
                                    }, {
                                        path: 'create',
                                        component: () =>
                                            import ('../views/administrator/ProtocolsGroup/cett02/create'),
                                    }, {
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/ProtocolsGroup/cett02/show'),
                                    }
                                ]
                            },
                            {
                                path: 'trp/',
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/ProtocolsGroup/trp/index'),
                                    }, {
                                        path: 'create',
                                        component: () =>
                                            import ('../views/administrator/ProtocolsGroup/trp/create'),
                                    }, {
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/ProtocolsGroup/trp/show'),
                                    }
                                ]
                            },
                            {
                                path: 'app/:slug',
                                name: 'Applications',
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/ProtocolsGroup/constructor/index'),
                                    },{
                                        path: 'create',
                                        component: () =>
                                            import ('../views/administrator/ProtocolsGroup/constructor/create'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/ProtocolsGroup/constructor/show'),
                                    },
                                ]
                            },
                        ]

                    },

                    /*---------------------------------*/

                    {
                        path: 'incubators-list/',
                        redirect: '/:lang/account',
                        component: {
                            render: (h) => h("router-view"),
                        },
                        children: [
                            {
                                path: '/',
                                name: 'IncubatorsAll',
                                meta: { accessList: ['read_applications'] },
                                component: () =>
                                    import ('../views/administrator/ApplicationsAll/incubator/list'),
                            },
                            {
                                path: 'app/:slug',
                                name: 'IncubatorsAll',
                                meta: { accessList: ['read_application'] },
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/ApplicationsAll/incubator/index'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/ApplicationsAll/incubator/show'),
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        path: 'trp-list/',
                        redirect: '/:lang/account',
                        component: {
                            render: (h) => h("router-view"),
                        },
                        children: [
                            {
                                path: '/',
                                name: 'TrpsAll',
                                meta: { accessList: ['read_applications'] },
                                component: () =>
                                    import ('../views/administrator/ApplicationsAll/trps/list'),
                            },
                            {
                                path: 'app/:slug',
                                name: 'TrpsAll',
                                meta: { accessList: ['read_application'] },
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/ApplicationsAll/trps/index'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/ApplicationsAll/trps/show'),
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        path: 'protocols-incubator/',
                        name: 'ProtocolsAllIncubator',
                        meta: { accessList: ['read_protocols'] },
                        component: {
                            render: (h) => h("router-view"),
                        },
                        children: [
                            {
                                path: '/',
                                component: () =>
                                    import ('../views/administrator/Protocols/incubator/list'),
                            },
                            {
                                path: 'app/:slug',
                                name: 'Applications',
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/Protocols/incubator/index'),
                                    },{
                                        path: 'create',
                                        component: () =>
                                            import ('../views/administrator/Protocols/incubator/create'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/Protocols/incubator/show'),
                                    },
                                ]
                            },
                        ]
                    },
                    {
                        path: 'protocols-incubator-group/',
                        name: 'ProtocolsGroupAllIncubator',
                        meta: { accessList: ['read_protocols_group'] },
                        component: {
                            render: (h) => h("router-view"),
                        },
                        children: [
                            {
                                path: '/',
                                component: () =>
                                    import ('../views/administrator/ProtocolsGroup/incubator/list'),
                            },
                            {
                                path: 'app/:slug',
                                name: 'Applications',
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/administrator/ProtocolsGroup/incubator/index'),
                                    },{
                                        path: 'create',
                                        component: () =>
                                            import ('../views/administrator/ProtocolsGroup/incubator/create'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/administrator/ProtocolsGroup/incubator/show'),
                                    },
                                ]
                            },
                        ]

                    },

                    /*---------------------------------*/

                    {
                        path: 'personal/',
                        name: 'PersonalData',
                        component: () =>
                            import ('../views/account/PersonalData/index'),
                    },
                    {
                        path: 'applications/',
                        redirect: '/:lang/account',
                        component: {
                            render: (h) => h("router-view"),
                        },
                        beforeEnter: (to, from, next) => {
                            if (to.meta.type && to.meta.page == 'create') {
                                api.get('moduleSettings/index',{
                                    params: {
                                        type: to.meta.type
                                    }
                                })
                                .then(response => {
                                    if (response.data && response.data[0].status == 1) {
                                        let setting = response.data[0];
                                        if (setting.start_date || setting.end_date) {
                                            if (setting.start_date <= moment(new Date()).format('YYYY-MM-DD HH:mm:ss') && setting.end_date >= moment(new Date()).format('YYYY-MM-DD HH:mm:ss')){
                                                next();
                                            } else {
                                                window.location = '/' + to.params.lang + '/account';
                                            }
                                        } else {
                                            next();
                                        }
                                    } else {
                                        window.location = '/' + to.params.lang + '/account';
                                    }
                                })
                                .catch(error => {
                                    if (error.response) {
                                        window.location = '/' + to.params.lang + '/account';
                                    }
                                });
                            } else {
                                next();
                            }
                        },
                        children: [
                            {
                                path: '/',
                                name: 'Applications',
                                meta: { accessList: ['read_application'] },
                                component: () =>
                                    import ('../views/account/Applications/index'),
                            },
                            {
                                path: 'cett01/',
                                name: 'Applications',
                                meta: { accessList: ['read_application'] },
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/account/Applications/cett01/index'),
                                    },{
                                        path: 'create',
                                        meta: { type: 'cett01', page: 'create' },
                                        component: () =>
                                            import ('../views/account/Applications/cett01/create'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/account/Applications/cett01/show'),
                                    },{
                                        path: ':id/edit',
                                        component: () =>
                                            import ('../views/account/Applications/cett01/edit'),
                                    },
                                ]
                            },
                            {
                                path: 'cett02/',
                                name: 'Applications',
                                meta: { accessList: ['read_application'] },
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/account/Applications/cett02/index'),
                                    },{
                                        path: 'create',
                                        meta: { type: 'cett02', page: 'create' },
                                        component: () =>
                                            import ('../views/account/Applications/cett02/create'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/account/Applications/cett02/show'),
                                    },{
                                        path: ':id/edit',
                                        component: () =>
                                            import ('../views/account/Applications/cett02/edit'),
                                    },
                                ]
                            },
                            {
                                path: 'trp/',
                                name: 'Applications',
                                meta: { accessList: ['read_application'] },
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/account/Applications/trp/index'),
                                    },{
                                        path: 'create',
                                        meta: { type: 'trp', page: 'create' },
                                        component: () =>
                                            import ('../views/account/Applications/trp/create'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/account/Applications/trp/show'),
                                    },{
                                        path: ':id/edit',
                                        component: () =>
                                            import ('../views/account/Applications/trp/edit'),
                                    },
                                ]
                            },
                            {
                                path: 'app/:slug',
                                name: 'Applications',
                                meta: { accessList: ['read_application'] },
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/account/Applications/constructor/index'),
                                    },{
                                        path: 'create',
                                        component: () =>
                                            import ('../views/account/Applications/constructor/create'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/account/Applications/constructor/show'),
                                    },{
                                        path: ':id/edit',
                                        component: () =>
                                            import ('../views/account/Applications/constructor/edit'),
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        path: 'incubators/',
                        redirect: '/:lang/account',
                        component: {
                            render: (h) => h("router-view"),
                        },
                        beforeEnter: (to, from, next) => {
                            if (to.meta.type && to.meta.page == 'create') {
                                api.get('moduleSettings/index',{
                                    params: {
                                        type: to.meta.type
                                    }
                                }).then(response => {
                                    if (response.data && response.data[0].status == 1) {
                                        let setting = response.data[0];
                                        if (setting.start_date || setting.end_date) {
                                            if (setting.start_date <= moment(new Date()).format('YYYY-MM-DD HH:mm:ss') && setting.end_date >= moment(new Date()).format('YYYY-MM-DD HH:mm:ss')){
                                                next();
                                            } else {
                                                window.location = '/' + to.params.lang + '/account';
                                            }
                                        } else {
                                            next();
                                        }
                                    } else {
                                        window.location = '/' + to.params.lang + '/account';
                                    }
                                })
                                .catch(error => {
                                    if (error.response) {
                                        window.location = '/' + to.params.lang + '/account';
                                    }
                                });
                            } else {
                                next();
                            }
                        },
                        children: [
                            {
                                path: '/',
                                name: 'Incubators',
                                meta: { accessList: ['read_application'] },
                                component: () =>
                                    import ('../views/account/Applications/incubator/list'),
                            },
                            {
                                path: 'app/:slug',
                                name: 'Incubators',
                                meta: { accessList: ['read_application'] },
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/account/Applications/incubator/index'),
                                    },{
                                        path: 'create',
                                        component: () =>
                                            import ('../views/account/Applications/incubator/create'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/account/Applications/incubator/show'),
                                    },{
                                        path: ':id/edit',
                                        component: () =>
                                            import ('../views/account/Applications/incubator/edit'),
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        path: 'trps/',
                        redirect: '/:lang/account',
                        component: {
                            render: (h) => h("router-view"),
                        },
                        beforeEnter: (to, from, next) => {
                            if (to.meta.type && to.meta.page == 'create') {
                                api.get('moduleSettings/index',{
                                    params: {
                                        type: to.meta.type
                                    }
                                }).then(response => {
                                    if (response.data && response.data[0].status == 1) {
                                        let setting = response.data[0];
                                        if (setting.start_date || setting.end_date) {
                                            if (setting.start_date <= moment(new Date()).format('YYYY-MM-DD HH:mm:ss') && setting.end_date >= moment(new Date()).format('YYYY-MM-DD HH:mm:ss')){
                                                next();
                                            } else {
                                                window.location = '/' + to.params.lang + '/account';
                                            }
                                        } else {
                                            next();
                                        }
                                    } else {
                                        window.location = '/' + to.params.lang + '/account';
                                    }
                                })
                                .catch(error => {
                                    if (error.response) {
                                        window.location = '/' + to.params.lang + '/account';
                                    }
                                });
                            } else {
                                next();
                            }
                        },
                        children: [
                            {
                                path: '/',
                                name: 'Trps',
                                meta: { accessList: ['read_application'] },
                                component: () =>
                                    import ('../views/account/Applications/trps/list'),
                            },
                            {
                                path: 'app/:slug',
                                name: 'Trps',
                                meta: { accessList: ['read_application'] },
                                component: {
                                    render: (h) => h("router-view"),
                                },
                                children: [
                                    {
                                        path: '/',
                                        component: () =>
                                            import ('../views/account/Applications/trps/index'),
                                    },{
                                        path: 'create',
                                        component: () =>
                                            import ('../views/account/Applications/trps/create'),
                                    },{
                                        path: ':id',
                                        component: () =>
                                            import ('../views/account/Applications/trps/show'),
                                    },{
                                        path: ':id/edit',
                                        component: () =>
                                            import ('../views/account/Applications/trps/edit'),
                                    }
                                ]
                            },
                        ]
                    },

                ]
            },


            {
                path: '/',
                component: () =>
                    import ('../views/Index.vue'),
                redirect: { name: 'Auth' },
                /*children: [

                    {
                        path: '/',
                        name: 'Home',
                        component: () =>
                            import ('../views/Main')
                    },


                ]*/
            },
            {
                path: '',
                component: () =>
                    import ('../views/IndexAuth.vue'),
                children: [{
                        path: 'login',
                        name: 'Auth',
                        component: () =>
                            import ('../views/Auth.vue'),
                    },

                    {
                        path: 'recovery',
                        name: 'Recovery',
                        component: () =>
                            import ('../views/AuthRecovery.vue'),
                    }, {
                        path: 'password/reset/:token',
                        name: 'ResetPassword',
                        component: () =>
                            import ('../views/AuthPassword.vue'),
                    },

                    {
                        path: 'registration',
                        name: 'Registration',
                        component: () =>
                            import ('../views/Registration.vue'),
                    },
                    {
                        path: '404',
                        name: '404',
                        component: () =>
                            import ('../views/NotFound.vue'),
                    }, {
                        path: '*',
                        redirect: '404'
                    },

                ]
            },
        ]
    }
]
