<template>
  <div class="header__mobile">

    <div class="header__mobile--top">
      <div class="header__mobile--logo">

        <div class="header__logo">
          <div class="header__logo--left">
            <a :href="'/'+$i18n.locale +'/'">
              <img src="../assets/img/logo.svg" alt="QazInnovations" class="d-none d-md-block">
              <img src="../assets/img/logo-mobile.svg" alt="QazInnovations" class="d-block d-md-none">
            </a>
          </div>
        </div>

      </div>
      <div class="header__mobile--close">
        <div class="mobile__close--click" @click="mobileActive">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6L6 18" stroke="#0E1839" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 6L18 18" stroke="#0E1839" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
    <div class="header__mobile--body">
      <div class="header__mobile--menu">
        <!--
        <ul class="menu">
          <li><a href="#about" @click="headerMenuClick">{{ $t('site.menu.about') }}</a></li>
          <li><a href="#faq" @click="headerMenuClick">{{ $t('site.menu.faq') }}</a></li>
        </ul>
        -->
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data: function () {
    return {
      mobileClick: true,
      activeMenu: false
    }
  },
  methods: {
    headerMenuClick(e) {
      e.preventDefault();
      if(e.target.hash){
        document.querySelector(e.target.hash).scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    },
    mobileActive() {
      this.mobileClick = false;
      document.body.classList.remove('mobile__active');
    },
  },
}
</script>

<style scoped>

</style>