import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import moment from 'moment';
import VueTheMask from 'vue-the-mask'

import i18n from './i18n'
import vuetify from './plugins/vuetify'
import vmodal from 'vue-js-modal'
import VTooltipPlugin from 'v-tooltip'

// import "@fancyapps/ui";
// import "@fancyapps/ui/dist/fancybox.css";

Vue.use(Vuex)
Vue.use(VTooltipPlugin)
Vue.use(vmodal)
Vue.use(VueTheMask)

Vue.config.productionTip = false

Vue.filter('nameFile', function(value) {
    let nameArr = value.split('/');
    return nameArr[nameArr.length - 1];
});

Vue.filter('maxFormat6', function(value) {
    if (value) {
        try {
            value = value.replace(/[^\d]/g, "");
        } catch (e) {

        }
        if (value >= 6) {
            value = 6;
        }
        return `${value}`
    }
})

Vue.filter('sumFormat', function(value) {
    if (value) {
        try {
            value = value.replace(/[^\d]/g, "");
        } catch (e) {

        }
        return `${value}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')
    }
})

Vue.filter('sumFormat20', function(value) {
    if (value) {
        try {
            value = value.replace(/[^\d]/g, "");
        } catch (e) {

        }
        if (value >= 20000000) {
            value = '20 000 000';
        }
        return `${value}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')
    }
})

Vue.filter('sumFormat150', function(value) {
    if (value) {
        try {
            value = value.replace(/[^\d]/g, "");
        } catch (e) {

        }
        if (value >= 150000000) {
            value = '150 000 000';
        }
        return `${value}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')
    }
})

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm')
    }
})

Vue.filter('dbFormatDate', function(value) {
    if (value) {
        return moment(String(value)).format('YYYY-MM-DD HH:mm:ss')
    }
})

Vue.filter('formatFullDate', function(value) {
    if (value) {
        return moment(String(value)).lang('ru').format('DD MMMM YYYY')
    }
})

Vue.filter('formatOnlyDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
    }
})

Vue.filter('formatTime', function(value) {
    if (value) {
        return moment(String(value)).format('HH:mm')
    }
})



const store = new Vuex.Store({
    state: {
        notification: null,
        userObject: null,
        userRoles: [],
        userPath: null,
    },
    getters: {
        _getUserRoles: state => {
            return state.userObject?.role?.id;
        },
        userPermission: state => roleList => {
            if (!roleList || !roleList.length) return true;
            let roleAccesses = state.userRoles.filter(element => {
                return roleList.find(el => {
                    if (el == element.value) return true;
                });
                //if(roleList.includes(element)) return element;
            });
            return roleAccesses.length ? true : false;
        }
    },
    mutations: {
        getUserRoles(state, roles) {
            state.userRoles = roles;
        },
        getUserObject(state, user) {
            state.userObject = user;
        },
        getUserNotifications(state, notification) {
            state.notification = notification;
        },
        getUserPath(state, path) {
            state.userPath = path;
        }
    }
});


Vue.component('v-header', require('./components/Header.vue').default);
Vue.component('v-mobile-header', require('./components/HeaderMobile.vue').default);
Vue.component('v-footer', require('./components/Footer.vue').default);

Vue.component('v-header-admin', require('./components/AdminHeader.vue').default);
Vue.component('v-footer-admin', require('./components/AdminFooter.vue').default);

Vue.component('v-sidebar', require('./components/Sidebar.vue').default);

Vue.component('v-preloader', require('./components/Preloader.vue').default);


router.beforeEach((to, from, next) => {
    let language = to.params.lang;
    let langArray = ['ru', 'kz', 'en'];
    if (!langArray.includes(language)) {
        //window.location = `/ru/404`
        window.location = `/ru/login`
    }
    if (!language) {
        language = 'ru'
    }

    // set the current language for i18n.
    i18n.locale = language
    next()
})



new Vue({
    router,
    vuetify,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app')