<template>
  <header class="header__site">
    <div class="container">

      <div class="header__site--row">

        <div class="header__left">

          <div class="header__logo">
            <div class="header__logo--left">
              <a :href="'/'+$i18n.locale +'/'">
                <img src="../assets/img/logo.svg" alt="QazInnovations" class="d-none d-md-block">
                <img src="../assets/img/logo-mobile.svg" alt="QazInnovations" class="d-block d-md-none">
              </a>
            </div>
          </div>

        </div>
        <div class="header__right">
     
          <div class="header__menu">
            <!--<ul class="menu">
              <li><a href="#about" @click="headerMenuClick">{{ $t('site.menu.about') }}</a></li>
              <li><a href="#faq" @click="headerMenuClick">{{ $t('site.menu.faq') }}</a></li>
            </ul>-->
          </div>
          <div class="header__lang">
            <div class="header__lang--top">
              <div class="header__lang--text">
                {{ langOn }}
              </div>
              <div class="header__lang--arrow">
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.5 9L12.5 15L18.5 9" stroke="#292E4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>

        
            <div class="header__lang--list">
              <ul>
                <li :class="{'header__lang--active': this.$i18n.locale == 'kz'}">
                  <a href="#" @click.prevent="setLocale('kz')">Қазақ</a>
                </li>
                <li :class="{'header__lang--active': this.$i18n.locale == 'ru'}">
                  <a href="#" @click.prevent="setLocale('ru')">Русский</a>
                </li>
                <li :class="{'header__lang--active': this.$i18n.locale == 'en'}">
                  <a href="#" @click.prevent="setLocale('en')">English</a>
                </li>
              </ul>
            </div>


          </div>
          <div class="header__burger">
            <div class="header__burger--click" @click="mobileActive">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 6H21" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3 12H21" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3 18H21" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
          </div>

        </div>

      </div>

    </div>
  </header>
</template>

<script>
export default {
  data: function () {
    return {
      mobileClick: false,
      loginClick: false,
    }
  },
  computed:{
    langOn: function (){
      var lang = this.$i18n.locale;
      if(lang == 'ru'){
        lang = 'Рус';
      }else if(lang == 'kz'){
        lang = 'Қаз';
      }else if(lang == 'en'){
        lang = 'Eng';
      }
      return lang
    }
  },
  methods: {
    headerMenuClick(e) {
      e.preventDefault();
      if(e.target.hash){
        document.querySelector(e.target.hash).scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    },
    logout() {
      localStorage.removeItem('token');
      this.$router.push('/ru/login');
    },

    mobileActive() {
      this.mobileClick = true;
      document.body.classList.add('mobile__active');
    },
    headerLogin: function() {
      this.loginClick = !this.loginClick;
    },
    setLocale(locale) {
      if(this.$i18n.locale != locale){
        this.$i18n.locale = locale
        this.$router.push({
          params: { lang: locale }
        })
      }
    }
  },
}
</script>