<template>
  <footer class="footer__site">
    <div class="container">

      <div class="footer__row">

        <div class="footer__left">

          <div class="header__logo footer__logo">
            <div class="header__logo--left footer__logo--left">
              <a :href="'/'+$i18n.locale +'/'">
                <img src="../assets/img/logo-grey.svg" class="d-none d-md-block">
                <img src="../assets/img/logo-mobile-grey.svg" class="d-block d-md-none">
              </a>
            </div>
          </div>

        </div>
        <div class="footer__right">

          <div class="header__menu footer__menu">
            <!--<ul class="menu">
              <li><a href="#about" @click="headerMenuClick">{{ $t('site.menu.about') }}</a></li>
              <li><a href="#faq" @click="headerMenuClick">{{ $t('site.menu.faq') }}</a></li>
            </ul>-->
          </div>

        </div>

      </div>

    </div>
  </footer>
</template>
<script>
export default {
  methods: {
    headerMenuClick(e) {
      e.preventDefault();
      if (e.target.hash) {
        document.querySelector(e.target.hash).scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    },
  }
}
</script>