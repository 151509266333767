import Vue from 'vue'
import axios from 'axios'

Vue.prototype.$axios = axios

const headers = {};

if (localStorage.token) {
    headers.authorization = localStorage.getItem('token')
}
// if (localStorage.refresh_token) {
//     console.log(localStorage.refresh_token);
// }
const urlApi = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_urlApi : process.env.VUE_APP_dev_urlApi;

headers['Accept-Language'] = 'ru';

const api = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_baseURL : process.env.VUE_APP_dev_baseURL,
    headers: headers
});

//	Vue.prototype.$api = api

export { axios, api, urlApi }


api.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401) {
        console.log(originalRequest);
        //localStorage.remove('access_token');
        api.post("auth/refresh-token", {
            refresh_token: localStorage.refresh_token
        })
        .then((response) => {
            localStorage.setItem('token', 'Bearer ' + response.data.token.access_token);
            localStorage.setItem('refresh_token', response.data.token.refresh_token);
            api(originalRequest);
        })
        .catch((err) => {
            console.log(err);
        });
    }
    return Promise.reject(error);
});
