<template>

  <div class="page__preloader">

    <div class="form__block--preloader">
      <div v-if="text" class="form__preloader--text form__block--line form__messages form__text">
        <label class="form__block--input">
          <span class="form__block--title">
            {{ text }}
          </span>
        </label>
      </div>
      <div v-if="message && message.status" class="form__preloader--text form__block--line form__messages form__messages--error">
        <label class="form__block--input">
          <span class="form__block--title">
            {{ message.text }}
          </span>
        </label>
      </div>
      <div v-else class="form__preloader--circle">
        <div class="preloader__circle">
          <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="36.5" cy="36" r="24" stroke="#FFF7E1" stroke-width="2"/>
            <g>
              <path d="M16.1604 23C13.5332 26.8866 12 31.5673 12 36.6045C12 50.0778 22.969 61 36.5 61C50.031 61 61 50.0778 61 36.6045C61 34.8685 60.8179 33.1748 60.4716 31.5413" stroke="#292E4F" stroke-width="4" stroke-linecap="round"/>
            </g>
          </svg>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
export default {
  props: {
    message: {
      type: Object
    },
    text: {
      type: String,
    },
  },
}
</script>

<style scoped>
.page__preloader {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.40);
}

.form__text {
  background-color: #fff;
}

.form__text .form__block--title {
  color: #000;
}
</style>